import type { SubmitFunction } from "@remix-run/react";
import { useFetcher } from "@remix-run/react";
import { useCallback, useEffect, useRef } from "react";
import type { useFetcher as useFetcherRR } from "react-router-dom";

export function useFetcherWithPromise(opts?: Parameters<typeof useFetcherRR>[0]) {
  const resolveRef = useRef<any>();
  const promiseRef = useRef<Promise<any>>();
  const fetcher = useFetcher(opts);

  const { load: loadFetcher, submit: submitFetcher, data, state } = fetcher;

  if (!promiseRef.current) {
    promiseRef.current = new Promise((resolve) => {
      resolveRef.current = resolve;
    });
  }

  const resetResolver = useCallback(() => {
    promiseRef.current = new Promise((resolve) => {
      // console.log(`RESOLVED PROMISE for useFetcherWithPromise`);
      resolveRef.current = resolve;
    });
  }, [promiseRef, resolveRef]);

  const submit: SubmitFunction = useCallback(
    async (...args) => {
      submitFetcher(...args);
      // console.log(`SUBMITTED PROMISE for useFetcherWithPromise`);

      return promiseRef.current;
    },
    [submitFetcher, promiseRef],
  );

  const load = useCallback(
    async (path) => {
      loadFetcher(path);
      return promiseRef.current;
    },
    [loadFetcher, promiseRef],
  );

  useEffect(() => {
    if (data && state === "idle") {
      resolveRef.current(data);
      resetResolver();
    }
  }, [data, resetResolver, state]);

  return { ...fetcher, submit, load };
}
